import styled from '@emotion/styled'
import { TABLET_MIN_BREAKPOINT } from '../constants/breakpoints'

const Box = styled.div`
  margin: 2.5rem 0;
  background-color: rgba(255, 255, 255, 0.3);

  @media screen and (${TABLET_MIN_BREAKPOINT}) {
    padding: 2.5rem;
  }
`

export default Box
