import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link, withPrefix } from 'gatsby'
import styled from '@emotion/styled'
import { Grid, Col, Row } from 'react-flexbox-grid'

import Layout from '../components/Layout'
import ResponsiveTitle from '../components/ResponsiveTitle'
import colors from '../constants/colors'
import FlipCard from '../components/FlipCard'
import BaseButton from '../components/BaseButton'
import Box from '../components/Box'
import { useIntl } from 'gatsby-plugin-intl'

const ContactBtnWrapper = styled.div`
  padding: 2.5rem;
  margin: 2.5rem 0;
`

export const WallArtPageTemplate = ({ title, description, printOptions }) => {
  const { formatMessage } = useIntl()
  return (
    <Grid fluid>
      <Row>
        <Col xs={12}>
          <ResponsiveTitle title={formatMessage({ id: 'wall-art.title' })} />
          <Box className="box content">
            <p>{formatMessage({ id: 'wall-art.intro.0' })}</p>
            <p>{formatMessage({ id: 'wall-art.intro.1' })}</p>
            <p>{formatMessage({ id: 'wall-art.intro.2' })}</p>
          </Box>
        </Col>
      </Row>
      <Row>
        {printOptions.map(({ image }, i) => (
          <Col key={i} xs={12} sm={6} lg={3}>
            <FlipCard
              title={formatMessage({ id: `wall-art.print-options.${i}.title` })}
              description={formatMessage({ id: `wall-art.print-options.${i}.description` })
                .split('.')
                .filter(s => s.length > 0)
                .map((paragraph, i) => (
                  <p key={i}>{paragraph}.</p>
                ))}
              image={image}
              backgroundColor={colors.isabelline}
            />
          </Col>
        ))}
      </Row>
      <Row>
        <Col xs={12}>
          <ContactBtnWrapper className="has-text-centered">
            <Link to={`${withPrefix('/')}contact`}>
              <BaseButton className="button is-large">
                {formatMessage({ id: 'wall-art.contact' })}
              </BaseButton>
            </Link>
          </ContactBtnWrapper>
        </Col>
      </Row>
    </Grid>
  )
}

WallArtPageTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  printOptions: PropTypes.array,
}

const WallArtPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <WallArtPageTemplate printOptions={frontmatter.printOptions} />
    </Layout>
  )
}

WallArtPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default WallArtPage

export const pageQuery = graphql`
  query WallArtPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "wall-art-page" } }) {
      frontmatter {
        printOptions {
          image {
            childImageSharp {
              fixed(width: 800, quality: 70) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`
