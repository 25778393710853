import React, { useRef } from 'react'
import T from 'prop-types'
import upperCase from 'lodash/upperCase'
import styled from '@emotion/styled'

import { TABLET_MIN_BREAKPOINT, DESKTOP_MIN_BREAKPOINT } from '../constants/breakpoints'
import BaseButton from './BaseButton'
import { useIntl } from 'gatsby-plugin-intl'

const CardContainer = styled.article`
  height: 28rem;
  margin: 1rem 0;
  background-color: transparent;

  @media screen and (${DESKTOP_MIN_BREAKPOINT}) {
    margin: 0;
  }
`
const CardFace = styled.div`
  background-color: ${props => props.backgroundColor ?? 'transparent'} !important;
`

// We need wrapper with a constant height to avoid items under title misaligning vertically.
const TitleWrapper = styled.div`
  height: 6rem;
  text-align: center;
`

const Button = styled(BaseButton)`
  position: static;
  margin: 0 3rem;

  @media screen and (${TABLET_MIN_BREAKPOINT}) {
    margin: 0 0.5rem;
  }
  @media screen and (${DESKTOP_MIN_BREAKPOINT}) {
    margin: 0 2rem;
  }
`

const DescriptionWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-y: scroll;
`

// TODO: Configure GatsbyImage instead plain img tag.
// TODO: Polyfill classList.add/remove.
const FlipCard = ({ title, description, image, backgroundColor }) => {
  const { formatMessage } = useIntl()
  const cardElemRef = useRef(null)

  const addFlippedClassName = () => {
    cardElemRef.current.classList.add('flip-card--flipped')
  }

  const removeFlippedClassName = () => {
    cardElemRef.current.classList.remove('flip-card--flipped')
  }

  return (
    <CardContainer ref={cardElemRef} className="flip-card box content has-box-shadow">
      <div className="flip-card-inner">
        <CardFace className="flip-card-front" backgroundColor={backgroundColor}>
          <TitleWrapper>
            <h3 className="has-text-weight-normal">{upperCase(title)}</h3>
          </TitleWrapper>
          <Button className="button" onClick={addFlippedClassName}>
            {formatMessage({ id: 'flip-card.details', defaultMessage: 'Details' })}
          </Button>
          <img
            className="has-box-shadow"
            src={image.childImageSharp.fixed.src}
            alt={`Example - ${title}`}
          />
        </CardFace>
        <CardFace className="flip-card-back" backgroundColor={backgroundColor}>
          <DescriptionWrapper>{description}</DescriptionWrapper>
          <Button className="button" onClick={removeFlippedClassName}>
            {formatMessage({ id: 'flip-card.back', defaultMessage: 'Back' })}
          </Button>
        </CardFace>
      </div>
    </CardContainer>
  )
}

FlipCard.propTypes = {
  title: T.string.isRequired,
  description: T.array.isRequired,
  image: T.object.isRequired,
  backgroundColor: T.string,
}

export default FlipCard
